var accordionList = function(arg) {
	var list = arg;
	var readMore = list.find(".view-more-btn");
	readMore.on("click", function() {
    var $this = $(this);
    var readMoreText = $this.attr("data-more");
    var readLessText = $this.attr("data-less");
    var alreadyOpened = list.children().hasClass("opened");
    var hasOpened = $this.parents('.item').hasClass("opened");
    
    if (hasOpened) {
      $this.html(readMoreText);
      $this.parents('.item').find(".box-details").slideUp('300');
      $this.parents('.item').removeClass("opened");  
    } else {
      if(alreadyOpened === true) {
        list.find(".box-details").slideUp('fast');
        list.children().removeClass("opened");
        list.children().find(".view-more-btn").html(readMoreText);
        $this.html(readLessText);
        $this.parents('.item').find(".box-details").slideDown('300');
        $this.parents('.item').addClass("opened");                                    

        setTimeout(function(){
          var scrollTo = $this.parents('.item').offset().top - 80;
          scrollToPosition(scrollTo); 
          // $("#postform3").followTo(scrollTo + 345);      
        }, 300)                    
        
        
      } else {
        $this.html(readLessText);
        $this.parents('.item').find(".box-details").slideDown('300');
        $this.parents('.item').addClass("opened");   

        setTimeout(function(){
          var scrollTo = $this.parents('.item').offset().top - 80;
          scrollToPosition(scrollTo);
           // $("#postform3").followTo(scrollTo + 345);
        }, 300)
      }
     
    }
  });
}

var scrollToPosition = function(arg){
  $('html, body').animate({
        scrollTop: arg
  }, 500);
    return false;
}


$(document).ready(function() {

    function footerMenu() {
      if ($(window).width() < 767) {
       $('.footer-menu li a').on("click", function(e){
          if($(this).attr("href")=="#") {
            e.preventDefault();
          }
          
          if ($(this).parent("li").hasClass("opened")) {
            $(this).parent("li").removeClass("opened");
            $(this).parent("li").find("ul").hide();
            return ;
          }
          $(this).parent("li").siblings('li').find("ul").hide();
          $(this).parent("li").siblings('li').removeClass("opened");
          $(this).parent("li").addClass("opened");
          $(this).parent("li").find("ul").show().addClass("opened");

       });
    }

    }

    var $navUlActive = $('ul.dropdown-menu.active');
    var $navLiActive = $('li.active');
    var hasDrawer = $('.drawer').length == 1 ? true : false;
    $('li.dropdown').on("mouseover", function(){
      $(this).siblings('li').removeClass("active");
      $(this).addClass("active");
      $(this).parents("ul").find("ul.active").removeClass("active");
      $(this).find("ul.dropdown-menu").addClass("active");
    });
    $('li.dropdown').on("mouseleave", function(){     
      $('li.dropdown.active').removeClass("active");
      $('ul.dropdown-menu.active').removeClass("active");
    
      if (!$navLiActive.hasClass("active")) {
        $navLiActive.addClass("active");
      }
      if (!$navUlActive.hasClass("active")) {
        $navUlActive.addClass("active");
      }     
    });

		// dropdown menu
		var menuOpened = false;
	  $('.dropdown').on("mouseover", function(){      
	    $(".header").addClass("drawer");
	  });   
	  $('.dropdown').on("mouseleave", function(){ 
      if (!$navUlActive.hasClass("active"))   {
        $(".header").removeClass("drawer");
      }	    
	  });
		$('.dropdown-menu').on("mouseleave", function(){
      if (!$navUlActive.hasClass("active"))   {
        $(".header").removeClass("drawer");
      }
		});

    // scroll down arrow
    $('.scroll, .back-to-top').on('click', function(e) {
      e.preventDefault();
      $('html, body').animate({ scrollTop: $($(this).attr('href')).offset().top - '50'}, 500, 'linear');
    });

		// sticky
		$(window).scroll(function() {
			var winTop = $(window).scrollTop();
			if (winTop >= 60) {
				$(".header").addClass("sticky-header");
			} else {
				$(".header").removeClass("sticky-header");
			}
		})  	


		// about us accordion
		var accordionListTag = $(".accordion-list");
    accordionListTag.each(function(){
      accordionList($(this));
    });  
    footerMenu();
    
    $(window).resize(function() {
      footerMenu();     
    });  

    // Global sites
    $('.global-sites').on('click', function(){
      $('.dropdown-menu').toggleClass('open');
    });

});


$.fn.toggleMoreLess = function(shouldExpand) {
  
  var $this = $(this),
      $para = $this.children('.text'),
      $readMore = $this.children('.more-less-btn'),
      initialHeight = $para.outerHeight(),
      targetHeight = $para.prop('scrollHeight');
  
  var doAnimation = function() {
    var height, btnText;
    
    if (shouldExpand) {
      height = targetHeight;
      btnText = 'Read less';
    }
    else {
      height = initialHeight;
      btnText = 'Read more';
    }
    
    $readMore.text(btnText);
    $para.animate({ height: height }, 'slow');
    shouldExpand = !shouldExpand; // Inverts state.
  };
  
  doAnimation();
  $this.children('.more-less-btn').on('click', doAnimation);
  return $this;
  
};
$('.text-wrap').toggleMoreLess();