// This file is transpiled
$(function(){  

  
    var opened = false;
   
    function menuTranslate(arg){
      if (arg == "show"){
        $('.mobile-nav').fadeIn(500); 
      }
      if (arg == "hide"){
        $('.mobile-nav').fadeOut(500); 
      }
       
         
    }
    function showLevel(arg){ 
      arg.addClass("opened");
      arg.find("ul").eq(0).addClass("opened");
      arg.siblings("li").removeClass("opened");
      arg.siblings("li").find(".opened").removeClass("opened");
     
    }
    function hideLevel(arg) {
      arg.removeClass("opened");
      arg.find(".opened").removeClass("opened");
      
    }

    $('.mobile-wrapper').on("showMenu", function(){
      menuTranslate("show");
    });
    $('.mobile-wrapper').on("hideMenu", function(){
       menuTranslate("hide");
    });
    $('.mobile-menu-trigger').on("click", function(){
      if (opened === true) {
        $('.mobile-wrapper').trigger("hideMenu");
        setTimeout(() => {
          $('.mobile-nav .opened').removeClass("opened");
          $('.mobile-nav .hide-level').remove();
          $('.mobile-nav .reverse-caret').removeClass("reverse-caret")
        }, 300);
       
        opened = false;          
      } else {
        $('.mobile-wrapper').trigger("showMenu");
        opened = true;          
      }      
    });



    
    $('.mobile-nav').addClass("vertical");



    // Optional (can be rendered in Razor)
    function hasChildren(arg) {  
      if(arg.children("ul").length > 0) {
        arg.addClass("hasChildren");
        arg.find("a").eq(0).addClass("hasChildren");
      }
    }
    $('.mobile-nav li').each(function(){     
      hasChildren($(this));
    }); 
    // End

    // Open level
    $('.mobile-nav').on("click", "a", function(e) {  
      var href = $(this).attr("href");
      var $target =  $(this).parent(".hasChildren");         
      if( href == "#" || href =="" ) {
        e.preventDefault();
      }     
   
      if ($target.hasClass("opened")) {
        $(this).removeClass("reverse-caret");
        hideLevel($target);
      } else {
        showLevel($target);
        $(this).addClass("reverse-caret");
      }
      
      
    });

    // Hide level
    $('.mobile-nav').on("click", ".hide-level", function(){
      var $target = $(this).parent("li");      
      hideLevel($target);
    });

  

  //Initialize level
  //MobileMenu();
 
});